//
//
//
//

import { ExceptionPage } from '@/components'

export default {
  components: {
    ExceptionPage
  }
}
